var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function BallTriangle(props) {
    var _a, _b, _c, _d;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var fill = props.fill;
    var stroke = (_b = props.stroke) !== null && _b !== void 0 ? _b : '#fff';
    var fillOpacity = props.fillOpacity;
    var strokeOpacity = props.strokeOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_c = props.height) !== null && _c !== void 0 ? _c : 57, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_d = props.width) !== null && _d !== void 0 ? _d : 57 }), { viewBox: "0 0 57 57" }),
        React.createElement("g", { transform: "translate(1 1)", strokeWidth: 2, fill: "none", fillRule: "evenodd" },
            React.createElement("circle", __assign({ cx: 5, cy: 50, r: 5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
                React.createElement("animate", { attributeName: "cy", begin: "0s", dur: "".concat(2.2 / speed, "s"), values: "50;5;50;50", calcMode: "linear", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "cx", begin: "0s", dur: "".concat(2.2 / speed, "s"), values: "5;27;49;5", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", __assign({ cx: 27, cy: 5, r: 5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
                React.createElement("animate", { attributeName: "cy", begin: "0s", dur: "".concat(2.2 / speed, "s"), from: 5, to: 5, values: "5;50;50;5", calcMode: "linear", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "cx", begin: "0s", dur: "".concat(2.2 / speed, "s"), from: 27, to: 27, values: "27;49;5;27", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", __assign({ cx: 49, cy: 50, r: 5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
                React.createElement("animate", { attributeName: "cy", begin: "0s", dur: "".concat(2.2 / speed, "s"), values: "50;50;5;50", calcMode: "linear", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "cx", from: 49, to: 49, begin: "0s", dur: "".concat(2.2 / speed, "s"), values: "49;5;27;49", calcMode: "linear", repeatCount: "indefinite" })))));
}
