import AudioIcon from './components/audio';
import BallTriangleIcon from './components/ball-triangle';
import BarsIcon from './components/bars';
import CirclesIcon from './components/circles';
import GridIcon from './components/grid';
import HeartsIcon from './components/hearts';
import OvalIcon from './components/oval';
import PuffIcon from './components/puff';
import RingsIcon from './components/rings';
import SpinningCirclesIcon from './components/spinning-circles';
import TailSpinIcon from './components/tail-spin';
import ThreeDotsIcon from './components/three-dots';
export var Audio = AudioIcon;
export var BallTriangle = BallTriangleIcon;
export var Bars = BarsIcon;
export var Circles = CirclesIcon;
export var Grid = GridIcon;
export var Hearts = HeartsIcon;
export var Oval = OvalIcon;
export var Puff = PuffIcon;
export var Rings = RingsIcon;
export var SpinningCircles = SpinningCirclesIcon;
export var TailSpin = TailSpinIcon;
export var ThreeDots = ThreeDotsIcon;
var LoadingIcons = {
    Audio: Audio,
    BallTriangle: BallTriangle,
    Bars: Bars,
    Circles: Circles,
    Grid: Grid,
    Hearts: Hearts,
    Oval: Oval,
    Puff: Puff,
    Rings: Rings,
    SpinningCircles: SpinningCircles,
    TailSpin: TailSpin,
    ThreeDots: ThreeDots,
};
export default LoadingIcons;
