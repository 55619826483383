var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function Audio(props) {
    var _a, _b, _c, _d, _e;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var fill = (_b = props.fill) !== null && _b !== void 0 ? _b : '#fff';
    var stroke = (_c = props.stroke) !== null && _c !== void 0 ? _c : 'transparent';
    var fillOpacity = props.fillOpacity;
    var strokeOpacity = props.strokeOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_d = props.height) !== null && _d !== void 0 ? _d : 80, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_e = props.width) !== null && _e !== void 0 ? _e : 55 }), { viewBox: "0 0 55 80" }),
        React.createElement("g", { transform: "matrix(1 0 0 -1 0 80)" },
            React.createElement("rect", __assign({ width: 10, height: 20, rx: 3 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
                React.createElement("animate", { attributeName: "height", begin: "0s", dur: "".concat(4.3 / speed, "s"), values: "20;45;57;80;64;32;66;45;64;23;66;13;64;56;34;34;2;23;76;79;20", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("rect", __assign({ x: 15, width: 10, height: 80, rx: 3 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
                React.createElement("animate", { attributeName: "height", begin: "0s", dur: "".concat(2 / speed, "s"), values: "80;55;33;5;75;23;73;33;12;14;60;80", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("rect", __assign({ x: 30, width: 10, height: 50, rx: 3 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
                React.createElement("animate", { attributeName: "height", begin: "0s", dur: "".concat(1.4 / speed, "s"), values: "50;34;78;23;56;23;34;76;80;54;21;50", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("rect", __assign({ x: 45, width: 10, height: 30, rx: 3 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
                React.createElement("animate", { attributeName: "height", begin: "0s", dur: "".concat(2 / speed, "s"), values: "30;45;13;80;56;72;45;76;34;23;67;30", calcMode: "linear", repeatCount: "indefinite" })))));
}
