var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function Bars(props) {
    var _a, _b, _c, _d;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var fill = (_b = props.fill) !== null && _b !== void 0 ? _b : '#fff';
    var stroke = props.stroke;
    var fillOpacity = props.fillOpacity;
    var strokeOpacity = props.strokeOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_c = props.height) !== null && _c !== void 0 ? _c : 140, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_d = props.width) !== null && _d !== void 0 ? _d : 135 }), { viewBox: "0 0 135 140" }),
        React.createElement("rect", __assign({ y: 10, width: 15, height: 120, rx: 6 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "height", begin: "0.5s", dur: "".concat(1 / speed, "s"), values: "120;110;100;90;80;70;60;50;40;140;120", calcMode: "linear", repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "y", begin: "0.5s", dur: "".concat(1 / speed, "s"), values: "10;15;20;25;30;35;40;45;50;0;10", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("rect", __assign({ x: 30, y: 10, width: 15, height: 120, rx: 6 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "height", begin: "0.25s", dur: "".concat(1 / speed, "s"), values: "120;110;100;90;80;70;60;50;40;140;120", calcMode: "linear", repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "y", begin: "0.25s", dur: "".concat(1 / speed, "s"), values: "10;15;20;25;30;35;40;45;50;0;10", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("rect", __assign({ x: 60, width: 15, height: 140, rx: 6 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "height", begin: "0s", dur: "".concat(1 / speed, "s"), values: "120;110;100;90;80;70;60;50;40;140;120", calcMode: "linear", repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "y", begin: "0s", dur: "".concat(1 / speed, "s"), values: "10;15;20;25;30;35;40;45;50;0;10", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("rect", __assign({ x: 90, y: 10, width: 15, height: 120, rx: 6 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "height", begin: "0.25s", dur: "".concat(1 / speed, "s"), values: "120;110;100;90;80;70;60;50;40;140;120", calcMode: "linear", repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "y", begin: "0.25s", dur: "".concat(1 / speed, "s"), values: "10;15;20;25;30;35;40;45;50;0;10", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("rect", __assign({ x: 120, y: 10, width: 15, height: 120, rx: 6 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "height", begin: "0.5s", dur: "".concat(1 / speed, "s"), values: "120;110;100;90;80;70;60;50;40;140;120", calcMode: "linear", repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "y", begin: "0.5s", dur: "".concat(1 / speed, "s"), values: "10;15;20;25;30;35;40;45;50;0;10", calcMode: "linear", repeatCount: "indefinite" }))));
}
