var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function Hearts(props) {
    var _a, _b, _c, _d;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var fill = (_b = props.fill) !== null && _b !== void 0 ? _b : '#fff';
    var stroke = props.stroke;
    var fillOpacity = props.fillOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_c = props.height) !== null && _c !== void 0 ? _c : 64, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_d = props.width) !== null && _d !== void 0 ? _d : 140 }), { viewBox: "0 0 140 64" }),
        React.createElement("path", __assign({ d: "M30.262 57.02L7.195 40.723c-5.84-3.976-7.56-12.06-3.842-18.063 3.715-6 11.467-7.65 17.306-3.68l4.52 3.76 2.6-5.274c3.717-6.002 11.47-7.65 17.305-3.68 5.84 3.97 7.56 12.054 3.842 18.062L34.49 56.118c-.897 1.512-2.793 1.915-4.228.9z", fillOpacity: fillOpacity }, { fill: fill, stroke: stroke }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.4 / speed, "s"), values: "0.5;1;0.5", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("path", __assign({ d: "M105.512 56.12l-14.44-24.272c-3.716-6.008-1.996-14.093 3.843-18.062 5.835-3.97 13.588-2.322 17.306 3.68l2.6 5.274 4.52-3.76c5.84-3.97 13.592-2.32 17.307 3.68 3.718 6.003 1.998 14.088-3.842 18.064L109.74 57.02c-1.434 1.014-3.33.61-4.228-.9z", fillOpacity: fillOpacity }, { fill: fill, stroke: stroke }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "0.7s", dur: "".concat(1.4 / speed, "s"), values: "0.5;1;0.5", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("path", __assign({ d: "M67.408 57.834l-23.01-24.98c-5.864-6.15-5.864-16.108 0-22.248 5.86-6.14 15.37-6.14 21.234 0L70 16.168l4.368-5.562c5.863-6.14 15.375-6.14 21.235 0 5.863 6.14 5.863 16.098 0 22.247l-23.007 24.98c-1.43 1.556-3.757 1.556-5.188 0z" }, { fill: fill, stroke: stroke }))));
}
