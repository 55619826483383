var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function Rings(props) {
    var _a, _b, _c, _d, _e;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var stroke = (_b = props.stroke) !== null && _b !== void 0 ? _b : '#fff';
    var strokeWidth = (_c = props.strokeWidth) !== null && _c !== void 0 ? _c : 2;
    var strokeOpacity = props.strokeOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_d = props.height) !== null && _d !== void 0 ? _d : 45, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_e = props.width) !== null && _e !== void 0 ? _e : 45 }), { viewBox: "0 0 45 45" }),
        React.createElement("g", __assign({ fill: "none", fillRule: "evenodd", transform: "translate(1 1)" }, { stroke: stroke, strokeWidth: strokeWidth, strokeOpacity: strokeOpacity }),
            React.createElement("circle", { cx: 22, cy: 22, r: 6 },
                React.createElement("animate", { attributeName: "r", begin: "1.5s", dur: "".concat(3 / speed, "s"), values: "6;22", calcMode: "linear", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "stroke-opacity", begin: "1.5s", dur: "".concat(3 / speed, "s"), values: "1;0", calcMode: "linear", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "stroke-width", begin: "1.5s", dur: "".concat(3 / speed, "s"), values: "2;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 22, cy: 22, r: 6 },
                React.createElement("animate", { attributeName: "r", begin: "3s", dur: "".concat(3 / speed, "s"), values: "6;22", calcMode: "linear", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "stroke-opacity", begin: "3s", dur: "".concat(3 / speed, "s"), values: "1;0", calcMode: "linear", repeatCount: "indefinite" }),
                React.createElement("animate", { attributeName: "stroke-width", begin: "3s", dur: "".concat(3 / speed, "s"), values: "2;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 22, cy: 22, r: 8 },
                React.createElement("animate", { attributeName: "r", begin: "0s", dur: "".concat(1.5 / speed, "s"), values: "6;1;2;3;4;5;6", calcMode: "linear", repeatCount: "indefinite" })))));
}
