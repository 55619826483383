var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function SpinningCircles(props) {
    var _a, _b, _c, _d;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var fill = (_b = props.fill) !== null && _b !== void 0 ? _b : '#fff';
    var stroke = props.stroke;
    var fillOpacity = props.fillOpacity;
    var strokeOpacity = props.strokeOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_c = props.height) !== null && _c !== void 0 ? _c : 58, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_d = props.width) !== null && _d !== void 0 ? _d : 58 }), { viewBox: "0 0 58 58" }),
        React.createElement("g", __assign({ transform: "translate(2 1)", fillRule: "evenodd" }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("circle", { cx: 42.601, cy: 11.462, r: 5 },
                React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.3 / speed, "s"), values: "1;0;0;0;0;0;0;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 49.063, cy: 27.063, r: 5, fillOpacity: 0 },
                React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.3 / speed, "s"), values: "0;1;0;0;0;0;0;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 42.601, cy: 42.663, r: 5, fillOpacity: 0 },
                React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.3 / speed, "s"), values: "0;0;1;0;0;0;0;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 27, cy: 49.125, r: 5, fillOpacity: 0 },
                React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.3 / speed, "s"), values: "0;0;0;1;0;0;0;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 11.399, cy: 42.663, r: 5, fillOpacity: 0 },
                React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.3 / speed, "s"), values: "0;0;0;0;1;0;0;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 4.938, cy: 27.063, r: 5, fillOpacity: 0 },
                React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.3 / speed, "s"), values: "0;0;0;0;0;1;0;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 11.399, cy: 11.462, r: 5, fillOpacity: 0 },
                React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.3 / speed, "s"), values: "0;0;0;0;0;0;1;0", calcMode: "linear", repeatCount: "indefinite" })),
            React.createElement("circle", { cx: 27, cy: 5, r: 5, fillOpacity: 0 },
                React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1.3 / speed, "s"), values: "0;0;0;0;0;0;0;1", calcMode: "linear", repeatCount: "indefinite" })))));
}
