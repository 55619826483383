var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function ThreeDots(props) {
    var _a, _b, _c, _d;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var fill = (_b = props.fill) !== null && _b !== void 0 ? _b : '#fff';
    var stroke = props.stroke;
    var fillOpacity = props.fillOpacity;
    var strokeOpacity = props.strokeOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_c = props.height) !== null && _c !== void 0 ? _c : 30, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_d = props.width) !== null && _d !== void 0 ? _d : 120 }), { viewBox: "0 0 120 30" }),
        React.createElement("circle", __assign({ cx: 15, cy: 15, r: 15 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "r", from: 15, to: 15, begin: "0s", dur: "".concat(0.8 / speed, "s"), values: "15;9;15", calcMode: "linear", repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "fill-opacity", from: 1, to: 1, begin: "0s", dur: "".concat(0.8 / speed, "s"), values: "1;.5;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 60, cy: 15, r: 9 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "r", from: 9, to: 9, begin: "0s", dur: "".concat(0.8 / speed, "s"), values: "9;15;9", calcMode: "linear", repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "fill-opacity", from: 0.5, to: 0.5, begin: "0s", dur: "".concat(0.8 / speed, "s"), values: ".5;1;.5", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 105, cy: 15, r: 15 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "r", from: 15, to: 15, begin: "0s", dur: "".concat(0.8 / speed, "s"), values: "15;9;15", calcMode: "linear", repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "fill-opacity", from: 1, to: 1, begin: "0s", dur: "".concat(0.8 / speed, "s"), values: "1;.5;1", calcMode: "linear", repeatCount: "indefinite" }))));
}
