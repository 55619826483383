var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function Oval(props) {
    var _a, _b, _c, _d, _e;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var stroke = (_b = props.stroke) !== null && _b !== void 0 ? _b : '#fff';
    var strokeWidth = (_c = props.strokeWidth) !== null && _c !== void 0 ? _c : 2;
    var strokeOpacity = props.strokeOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_d = props.height) !== null && _d !== void 0 ? _d : 38, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_e = props.width) !== null && _e !== void 0 ? _e : 38 }), { viewBox: "0 0 38 38", overflow: "visible" }),
        React.createElement("g", __assign({ transform: "translate(1 1)", fill: "none", fillRule: "evenodd" }, { stroke: stroke, strokeWidth: strokeWidth, strokeOpacity: strokeOpacity }),
            React.createElement("circle", { strokeOpacity: 0.5, cx: 18, cy: 18, r: 18 }),
            React.createElement("path", { d: "M36 18c0-9.94-8.06-18-18-18" },
                React.createElement("animateTransform", { attributeName: "transform", type: "rotate", from: "0 18 18", to: "360 18 18", dur: "".concat(1 / speed, "s"), repeatCount: "indefinite" })))));
}
