var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export default function Grid(props) {
    var _a, _b, _c, _d;
    var speed = Number(String((_a = props.speed) !== null && _a !== void 0 ? _a : 1));
    var fill = (_b = props.fill) !== null && _b !== void 0 ? _b : '#fff';
    var stroke = props.stroke;
    var fillOpacity = props.fillOpacity;
    var strokeOpacity = props.strokeOpacity;
    return (React.createElement("svg", __assign({}, __assign(__assign({}, props), { className: props.className
            ? "icon-loading ".concat(props.className)
            : 'icon-loading', fill: undefined, fillOpacity: undefined, height: (_c = props.height) !== null && _c !== void 0 ? _c : 105, speed: undefined, stroke: undefined, strokeOpacity: undefined, strokeWidth: undefined, width: (_d = props.width) !== null && _d !== void 0 ? _d : 105 }), { viewBox: "0 0 105 105" }),
        React.createElement("circle", __assign({ cx: 12.5, cy: 12.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "0s", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 12.5, cy: 52.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "100ms", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 52.5, cy: 12.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "300ms", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 52.5, cy: 52.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "600ms", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 92.5, cy: 12.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "800ms", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 92.5, cy: 52.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "400ms", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 12.5, cy: 92.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "700ms", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 52.5, cy: 92.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "500ms", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" })),
        React.createElement("circle", __assign({ cx: 92.5, cy: 92.5, r: 12.5 }, { fill: fill, stroke: stroke, fillOpacity: fillOpacity, strokeOpacity: strokeOpacity }),
            React.createElement("animate", { attributeName: "fill-opacity", begin: "200ms", dur: "".concat(1 / speed, "s"), values: "1;.2;1", calcMode: "linear", repeatCount: "indefinite" }))));
}
